import React, {useState} from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';

import Footer from '../components/Footer/Footer';

const StyledContent = styled.div`
  padding: 80px 20px;
  max-width: 1000px;
  margin: 0 auto;
  
  .headingWrapper {
    text-align: center;
    margin-bottom: 70px;
    
    p {
      font-size: 18px;
    }
  }
  
  h2 {
    font-weight: 500;
    font-size: 26px;
    margin-top: 30px;
    margin-bottom: 12px;
    line-height: 1.3;
    
    &.cookies {
      margin-top: 80px;
      font-weight: 100;
      font-size: 32px;
      text-align: center;
      margin-bottom: 30px;
      
      strong {
        font-weight: 600;
      }
    }
  }
  
  p {
    line-height: 1.3;
    margin-bottom: 15px;
    font-family: 'Manrope';
    
    strong {
      font-family: "Visby CF";
      font-weight: 600;
    }
  }
  
  ul, ol {
   li {
    font-family: 'Manrope';
   }
  }
`;

const StyledHeader = styled.div`
  background-color: #360467;
  padding: 150px 20px 80px;
  
  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 100px 20px 50px;
  }
  
  h1 {
    color: #fff;
    text-align: center;
    font-size: 60px;
    font-weight: 100;
    
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
      font-size: 36px;
    }
    
    strong {
      font-weight: 500;
    }
  }
`;

// eslint-disable-next-line react/display-name
export default () => {
  return (
    <Layout>
      <SEO title="Polityka Prywatności"/>
      <StyledHeader><h1>Polityka <strong>Prywatności i Cookies</strong></h1></StyledHeader>
      <StyledContent>
        <div className="headingWrapper">
          <p>Niniejsza Polityka Prywatności dotyczy stron: <br/>
            www.mercho.pl <br/>
            zwanych dalej Stroną Internetową. <br/></p>
        </div>
        <p>Przykładamy dużą wagę do ochrony prywatności i poufności danych osobowych wprowadzanych do formularzy
          elektronicznych na stronach internetowych mercho.pl oraz zapewniamy, że Administrator z należytą starannością
          dobiera i stosuje odpowiednie środki techniczne i organizacyjne zapewniające ochronę przetwarzanych danych
          osobowych Użytkowników.</p>
        <p>Dane osobowe przetwarzane są zgodnie z rozporządzeniem Parlamentu Europejskiego i Rady (UE) z dnia 27 kwietnia
          2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego
          przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej: RODO), ustawą o ochronie danych osobowych z
          dnia 10 maja 2018 r. oraz ustawą o świadczeniu usług drogą elektroniczną.</p>
        <p>Podanie danych osobowych, a także zgoda na ich przetwarzanie są całkowicie dobrowolne. Wszelkie przekazane nam
          dane osobowe są przetwarzane wyłącznie w zakresie i celu, na jaki wyraziliście Państwo zgodę.</p>
        <h2>1. Kto jest Administratorem danych osobowych Strony Internetowej?</h2>
        <p>Administratorem danych osobowych Strony Internetowej (dalej: Administrator): Mercho Dawid Sularz z siedzibą w
          Krakowie, ul. Matematyków Krakowskich 14, 30-698 Kraków</p>
        <p>Mercho Dawid Sularz jest także Administratorem danych osobowych wprowadzanych do formularzy kontaktowych,
          wskazanych szczegółowo w pkt. 2 poniżej, zamieszczonych na podstronach serwisu www.mercho.pl</p>
        <h2>2. Jakie dane oraz w jakim celu wykorzystuje Strona Internetowa?</h2>
        <p><strong>2.1. Formularze kontaktowe dostępne na stronie www.mercho.pl</strong></p>
        <p>W celu zapewnienia Państwu możliwości kontaktu na Stronie Internetowej udostępniono formularze kontaktowe
          opisane szczegółowo poniżej. Strony Internetowe wykorzystują jedynie Państwa dane osobowe dobrowolnie
          wprowadzone do wskazanych niżej formularzy kontaktowych.</p>
        <p>Formularz Kontaktowy – Podstawowy:</p>
        <p>Formularz pozwala na zebranie następujących danych: imię i nazwisko, telefon, adres e-mail oraz ograniczonych
          danych adresowych: miasto oraz województwo. W celu skorzystania z funkcji formularza kontaktowego należy podać
          co najmniej adres e-mail lub numer telefonu. Podanie danych zawartych w formularzu kontaktowym jest całkowicie
          dobrowolne, jednakże brak podania danych będzie skutkował brakiem możliwości skontaktowania się z Państwem przez
          Mercho Dawid Sularz</p>
        <h2>3. Na jakiej podstawie zbieramy oraz przetwarzamy dane osobowe? Cele i okresy przetwarzania danych</h2>
        <p>Państwa dane osobowe zbieramy w celu umożliwienia Państwu skontaktowania się z Administratorem lub z lokalnym
          przedstawicielem Mercho Dawid Sularz, komunikacji związanej ze świadczenie usług oraz w celu prowadzenia
          marketingu bezpośredniego (wysyłka zamówionych katalogów).</p>
        <p>Podstawą przetwarzania danych jest udzielona przez Państwa dobrowolna zgoda (art. 6 ust. 1a RODO). Zawsze
          przetwarzamy Państwa dane osobowe w określonym celu i tylko te dane, które są konieczne do jego osiągnięcia.
          Zgodna na przetwarzanie danych może zostać w każdej chwili wycofana, bez żadnych negatywnych konsekwencji, w
          sposób równie łatwy jak jej wyrażenie. Wycofanie zgody nie wpływa na zgodność z prawem wykorzystania Państwa
          danych przed cofnięciem zgody.</p>
        <p>Dane osobowe udostępnione poprzez formularze kontaktowe przetwarzamy przez okres niezbędny do udzielenia
          odpowiedzi na pytanie oraz załatwienia sprawy, a w przypadku zgody na przesłanie katalogu przez okres niezbędny
          do jego wysłania. W celach marketingowych przetwarzamy dane nie dłużej niż do momentu wycofania zgody lub
          wniesienia przez Państwa sprzeciwu wobec takiego przetwarzania.
          Dane archiwizujemy i przetwarzamy dla celów ustalenia i obrony przed ewentualnymi roszczeniami przez okres 3 lat
          od dnia ich uzyskania (podstawą prawną jest prawnie uzasadniony interes Administratora, art. 6 ust. 1f RODO).</p>
        <h2>4. Komu przekazujemy Państwa dane</h2>
        <p>Państwa dane możemy przekazywać:</p>
        <p>a) podmiotom przetwarzającym dane w imieniu Administratora, tj.: wyspecjalizowanym dostawcom usług
          przechowywania danych, wyspecjalizowanym podmiotom świadczącym na naszą rzecz usługi IT, hostingowe, podmiotom
          wspierającym nas przy obsłudze korespondencji, obsłudze prawnej, marketingowej oraz podmiotom obsługującym i
          utrzymującym naszą sieć telekomunikacyjną;</p>
        <p>b) innym administratorom danych przetwarzającym Państwa dane we własnym imieniu: podmiotom prowadzącym
          działalność pocztową lub kurierską</p>
        <p>Dane przekazywane podmiotom zewnętrznym są używane tylko do świadczenia Państwu naszych usług. Administrator
          zapewnia, aby każde udostępnienie lub powierzenie do przetwarzania Państwa danych innym podmiotom było zgodne z
          obowiązującymi przepisami w zakresie ochrony danych osobowych. Nigdy nie sprzedajemy Państwa danych osobowych
          ani nie wymieniamy się nimi w celach marketingowych z podmiotami trzecimi, poza przypadkami udostępnienia danych
          opisanymi w niniejszej Polityce Prywatności.</p>
        <h2>5. Polityka prywatności bezpieczeństwa partnerów Strony Internetowej i media społecznościowe</h2>
        <p>Dla Państwa wygody Strona Internetowa zawiera funkcjonalności zintegrowane z zewnętrznymi platformami mediów
          społecznościowych (Facebook, Instagram, YouTube), które można aktywować manualnie. W takim przypadku strony
          trzecie, które obsługują platformy będą mogły identyfikować użytkownika, określać w jaki sposób korzysta z
          witryny internetowej, łączyć i przechowywać te informacje z profilem w mediach społecznościowych. Korzystanie ze
          wskazanych funkcjonalności może wiązać się z zapisaniem w Państwa urządzeniu końcowym cookies tych podmiotów.
          Aktywacja wskazanych funkcjonalności nie jest obowiązkowa – możecie Państwo korzystać z funkcjonalności Strony
          Internetowej bez integracji z platformami zewnętrznymi.</p>
        <p>Administrator strony Internetowej bardzo starannie dobiera swoich partnerów biznesowych i równie starannie
          weryfikuje zamieszczane na Stronie Internetowej linki do innych stron internetowych. Administrator strony
          internetowej nie odpowiada jednak za standard i politykę ochrony prywatności stosowaną przez właścicieli,
          operatorów czy administratorów tych stron. Dlatego zalecamy weryfikację każdego z naszych partnerów w zakresie
          własnym i samodzielne podjęcie decyzji, czy chcą Państwo powierzyć im Swoje dane osobowe.</p>
        <h2>6. Państwa prawa – prawo dostępu, modyfikacji, do wniesienia sprzeciwu i skargi</h2>
        <p>Zgodnie z RODO, przysługuje Państwu prawo do:</p>
        <ul>
          <li>dostępu do swoich danych oraz otrzymania ich kopii;</li>
          <li>sprostowania (poprawiania, uzupełniania) swoich danych;</li>
          <li>usunięcia danych (przetwarzanych bezpodstawnie lub na podstawie odwołanej zgody),</li>
          <li>ograniczenia przetwarzania danych (wstrzymanie operacji na danych lub nieusuwanie danych – w zależności od
            treści Państwa wniosku);</li>
          <li>złożenia wniosku o przeniesienie danych do innego administratora (art. 20 RODO);</li>
          <li>złożenia sprzeciwu wobec przetwarzania danych, jeżeli Państwa dane są przetwarzane we celu marketingu
            bezpośredniego lub podstawą przetwarzania danych jest prawnie uzasadniony interes Administratora lub interes
            publiczny.</li>
        </ul>
        <p>Z powyższych praw możecie Państwo skorzystać kontaktując się z nami pod adresem wskazanym w pkt. 11, mailowo,
          telefonicznie lub listownie.
          Przysługuje Państwu prawo wniesienia skargi do organu nadzorczego, którym jest Prezes Urzędu Ochrony Danych
          Osobowych, jeżeli uważacie Państwo, że przetwarzanie danych narusza przepisy prawa.</p>
        <h2>7. Przekazywanie danych poza Europejski Obszar Gospodarczy (EOG)</h2>
        <p>Państwa dane osobowe mogą być przekazywane poza Europejski Obszar Gospodarczy jedynie do Google w przypadku
          wyrażenia zgody na korzystanie z plików Cookies. Google jest odbiorcą certyfikowanym w ramach Tarczy Prywatności
          Stanów Zjednoczonych (Privacy Shield) co jest równoznaczne z zapewnieniem odpowiednich zabezpieczeń w celu
          ochrony danych zgodnie z art. 46 ust. 2 RODO.</p>
        <h2>8. Dane o lokalizacji</h2>
        <p>Strona może wykorzystywać dane o Państwa lokalizacji w celu optymalizacji jej działania oraz udostępnienia
          Państwu funkcjonalności w postaci możliwości wyświetlenia lokalnych przedstawicieli Mercho Dawid Sularz –
          najbliższych Państwa lokalizacji. Możliwość udostępnienia lokalizacji można zablokować w ustawieniach Państwa
          przeglądarki.</p>
        <h2>9. Profilowanie i zautomatyzowane podejmowanie decyzji</h2>
        <p>Państwa dane będą przetwarzane przez Administratora w sposób zautomatyzowany, jednakże nie będzie to wywoływać
          wobec Państwa żadnych skutków prawnych lub w podobny sposób istotnie wpływać na Państwa sytuację.
          Profilowanie danych osobowych przez Administratora polega na przetwarzaniu Państwa danych (również w sposób
          zautomatyzowany), poprzez wykorzystywanie ich do oceny niektórych informacji w celach statystycznych,
          monitorowania ruchu na Stronie Internetowej oraz analiz marketingowych.</p>
        <h2>10. Uaktualnienia Polityki Prywatności</h2>
        <p>Strona Internetowa zastrzega sobie prawo zmiany polityki prywatności, o ile będzie wymagać tego obowiązujące
          prawo, ulegną zmianie warunki technologiczne funkcjonowania Strony Internetowej lub zmiana będzie wprowadzać
          standard wyższy od minimum wymaganego prawem.</p>
        <h2>11. Kontakt</h2>
        <p>W przypadku jakichkolwiek pytań lub wątpliwości co do polityki prywatności i bezpieczeństwa Państwa danych
          osobowych zachęcamy do kontaktu:</p>

        <div className="kontakt">
          <p><strong>Dane kontaktowe Inspektora Ochrony Danych Mercho Dawid Sularz:</strong></p>

          <p><strong>Dawid Sularz <br />
            e-mail: biuro@mercho.pl</strong></p>
        </div>

        <h2 className="cookies">Polityka <strong>Cookies</strong></h2>
        <p>Mercho Dawid Sularz – administrator stron internetowych w domenie mercho.pl może stosować pliki „cookies”.
          Poprzez pliki „cookies” należy rozumieć dane informatyczne, w szczególności pliki tekstowe, przechowywane w
          urządzeniach końcowych użytkowników przeznaczone do korzystania ze stron internetowych.</p>
        <p>Informacje zbierane przy pomocy „cookies” pozwalają dostosowywać usługi i treści do indywidualnych potrzeb i
          preferencji Użytkowników, jak również służą do opracowywania ogólnych statystyk dotyczących korzystania przez
          Użytkowników z Serwisu. Dane osobowe gromadzone przy użyciu plików „cookies” mogą być zbierane wyłącznie w celu
          wykonywania określonych funkcji na rzecz Użytkownika. Takie dane są zaszyfrowane w sposób uniemożliwiający
          dostęp do nich osobom nieuprawnionym.</p>
        <p>Stosowane są, dwa rodzaje plików „cookies” – „sesyjne” oraz „stałe”. Pierwsze z nich są plikami tymczasowymi,
          które pozostają na urządzeniu użytkownika, aż do wylogowania ze strony internetowej lub wyłączenia
          oprogramowania (przeglądarki internetowej). „Stałe” pliki pozostają na urządzeniu użytkownika przez czas
          określony w parametrach plików „cookies” albo do momentu ich ręcznego usunięcia przez Użytkownika.</p>
        <p>Standardowo oprogramowanie służące do przeglądania stron internetowych domyślnie dopuszcza umieszczanie plików
          „cookies” na urządzeniu końcowym. Ustawienia te mogą zostać zmienione w taki sposób, aby blokować automatyczną
          obsługę plików „cookies” w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym przesłaniu
          na urządzenie użytkownika. Szczegółowe informacje o możliwości i sposobach obsługi plików „cookies” dostępne są
          w ustawieniach oprogramowania (przeglądarki internetowej). Wyłączenie w przeglądarce internetowej opcji
          pozwalającej na zapisywanie plików „cookies” zasadniczo nie uniemożliwia korzystanie ze strony internetowej,
          może jednak spowodować utrudnienia.</p>
        <p>Pliki „cookies” wykorzystywane przez partnerów operatora strony internetowej, w tym w szczególności użytkowników
          strony internetowej, podlegają ich własnej polityce prywatności.</p>
      </StyledContent>
      <Footer>
        <Footer.Heading>
          <strong>Skontaktuj się</strong> z nami
        </Footer.Heading>
        <Footer.Paragraph>
          Blogerze! Youtuberze! Influencerze! Czekamy aż do nas napiszesz lub
          zadzwonisz. Jak się do nas nie odezwiesz, to trudno będzie nam Ci
          pomóc!
        </Footer.Paragraph>
      </Footer>
    </Layout>
  );
};
